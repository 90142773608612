<template>
  <span
    class="tw-transition-all !tw-inline-flex !tw-items-center !tw-rounded-full"
    :class="[classes, {'!tw-text-xs': smallIcon}]"
  >
    <svg
      v-if="hasIcon"
      :class="smallIcon ? '!tw-mr-1' : '!tw-mr-2'"
      :height="smallIcon ? 14 : 24"
      viewBox="0 0 24 24"
      :width="smallIcon ? 14 : 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m0 0h24v24h-24z"
        fill="none"
      />
      <path
        d="m16 4h-8v.5a.5.5 0 0 1 -1 0v-.5h-1.5a1.5 1.5 0 0 0 -1.5 1.5v2.5h16v-2.5a1.5 1.5 0 0 0 -1.5-1.5h-1.5v.5a.5.5 0 0 1 -1 0zm1-1h1.5a2.5 2.5 0 0 1 2.5 2.5v13.007a2.5 2.5 0 0 1 -2.5 2.5h-13a2.5 2.5 0 0 1 -2.5-2.5v-13.007a2.5 2.5 0 0 1 2.5-2.5h1.5v-.5a.5.5 0 0 1 1 0v.5h8v-.5a.5.5 0 0 1 1 0zm3 6h-16v9.507a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5zm-15 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1 -.5.5h-2a.5.5 0 0 1 -.5-.5zm1 .5v1h1v-1z"
        fill="currentColor"
        transform="translate(0 .496)"
      />
    </svg>
      {{ text }}
  </span>
</template>

<script>
export default {
  name: 'ChatCtaButton',

  props: {
    text: {
      type: String,
      required: true
    },

    hasIcon: {
      type: Boolean,
      default: false
    },

    smallIcon: {
      type: Boolean,
      default: false
    },

    classes: {
      type: String,
      default: ''
    },
  }
}
</script>
