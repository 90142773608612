<template>
  <li class="!tw-block tw-relative !tw-bg-gray-200 dark:!tw-bg-[#262629] !tw-rounded-xl !tw-p-3">
    <p class="!tw-block !tw-mb-2 !tw-pb-0 !tw-text-sm !tw-leading-[18px] !tw-text-gray-600 dark:!tw-text-white">
      <slot/>
    </p>

    <span
      class="tw-absolute z-10 !tw-bottom-[0.1rem] !tw-right-2 tw-flex tw-gap-x-1 !tw-text-xs !tw-text-gray-400 dark:!tw-text-[#6A7272]">
      {{ currentTime }}
      <svg
        class="!tw-w-4 !tw-h-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 15"
      >
        <path
          fill="currentColor"
          d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
        />
      </svg>
    </span>

    <svg
      class="tw-absolute !tw--z-10 !tw-bottom-0 !tw--left-1 !tw-text-gray-200 dark:!tw-text-[#262629]"
      height="19"
      viewBox="0 0 19 19"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m0 19a13.951 13.951 0 0 0 3.073-4.484 13.909 13.909 0 0 0 1.127-5.516v-9h14.8a18.88 18.88 0 0 1 -1.493 7.4 18.936 18.936 0 0 1 -4.072 6.039 18.936 18.936 0 0 1 -6.035 4.068 18.881 18.881 0 0 1 -7.4 1.493z"
        fill="currentColor"/>
    </svg>
  </li>
</template>

<script>
export default {
  name: 'ChatMessage',

  data() {
    return {
      currentTime: undefined
    }
  },

  mounted() {
    this.currentTime = `${ ('0' + new Date().getHours()).slice(-2) }:${ ('0' + new Date().getMinutes()).slice(-2) }`
  }
}
</script>
