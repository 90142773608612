<template>
  <div
    id="sam-chat-button"
    class="tw-flex"
    :class="[settings.showLeft === '1' ? '' : 'tw-flex-row-reverse']"
  >
    <div
      class="tw-relative !tw-rounded-full !tw-h-16 !tw-w-16 !tw-transition-all !tw-duration-500 !tw-ease-in-out tw-flex tw-justify-center tw-align-center tw-shadow hover:tw-shadow-3xl hover:!tw-cursor-pointer"
      :style="{ backgroundColor: whatsappBgColor }"
      @click="toggleWindow"
    >
      <!-- NOTIFICATION BUBBLE -->
      <span v-if="!clicked" class="tw-flex tw-justify-center !tw-items-center !tw-h-6 !tw-w-6 tw-absolute !tw-top-[-4px] !tw-right-[-4px]">
        <span
          class="!tw-bg-red-600 tw-animate-bg-pulsate !tw-absolute !tw-inline-flex !tw-h-full !tw-w-full !tw-rounded-full"/>
        <span
          class="!tw-bg-red-600 !tw-relative !tw-inline-flex !tw-rounded-full !tw-h-4 !tw-w-4 !tw-text-white !tw-text-xs tw-flex tw-justify-center !tw-items-center">
          1
        </span>
      </span>

      <!-- SVG BUTTON -->
      <svg
        class="!tw-w-8 !tw-w-8"
        :style="{ color: whatsappTextColor }"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z">
        </path>
      </svg>
    </div>

    <transition name="fade">
      <div
        v-show="settings.ctaText && showCta"
        class="tw-flex !tw-items-center hover:!tw-cursor-pointer"
        @click="ctaClicked"
      >
        <chat-cta-button
          class="tw-h-[40px] !tw-p-2 !tw-px-4 !tw-text-sm !tw-mx-4"
          :text="showUrlCta ? settings.ctaUrlText : settings.ctaText"
          :style="{backgroundColor: ctaUrlBgColor, color: ctaUrlTextColor}"
          :has-icon="showUrlCta && settings.ctaUrlShowIcon === '1'"
        />
      </div>
    </transition>

    <chat-cta-button
      ref="ctaButton"
      classes="!tw-hidden !tw-mx-4 !tw-p-1 !tw-px-2"
      :text="settings.ctaUrlText"
      :style="{backgroundColor: ctaUrlBgColor, color: ctaUrlTextColor}"
      :has-icon="settings.ctaUrlShowIcon === '1'"
      small-icon
    />
  </div>
</template>

<script>
import axios from '@/shared/axios'
import ChatCtaButton from '@/frontend/components/ChatCtaButton'

export default {
  name: 'ChatToggleButton',

  components: {
    ChatCtaButton
  },

  props: {
    showWindow: {
      type: Boolean,
      default: false
    },

    settings: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      show: false,
      showCta: false,
      showUrlCta: false,
      clicked: false,
      linkCtaMessageSent: false
    }
  },

  computed: {
    whatsappBgColor() {
      return this.settings.whatsappBgColor
    },

    whatsappTextColor() {
      return this.settings.whatsappTextColor
    },

    ctaUrlBgColor() {
      return this.settings.ctaUrlBgColor
    },

    ctaUrlTextColor() {
      return this.settings.ctaUrlTextColor
    },

    isDarkMode() {
      return this.settings.darkMode === '1'
    },

    hasLinkCta() {
      return this.settings.ctaUrlText &&
        this.settings.ctaUrl &&
        this.settings.showCtaUrl === '1'
    }
  },

  watch: {
    showWindow(show) {
      this.show = show
    }
  },

  mounted() {
    this.openOnElements()

    this.addLinkCtaMessage()

    // Show normal cta
    setTimeout(() => {
      this.showCta = true
      this.showLinkCta()
    }, 3 * 1000)
  },

  methods: {
    addLinkCtaMessage() {
      if (this.linkCtaMessageSent) {
        return
      }

      setTimeout(() => {
        if (this.hasLinkCta && this.$refs.ctaButton) {
          this.linkCtaMessageSent = true

          this.$emit('showCtaMessage', {
            message: this.settings.ctaUrlMessage,
            button: this.$refs.ctaButton.$el.outerHTML
          })
        }
      }, 500)
    },

    showLinkCta() {
      // Hide normal cta
      setTimeout(() => {
        this.showCta = false
        if (!this.hasLinkCta) {
          return
        }

        // Evaluate link cta
        setTimeout(() => {
          // Show link cta
          this.showUrlCta = true
          this.showCta = true
        }, 1000)
      }, 12 * 1000)
    },

    toggleWindow() {
      this.clicked = true
      this.show = !this.show
      this.$emit('showWindow', this.show)
    },

    goToUrl() {
      window.location.href = this.settings.ctaUrl
    },

    ctaClicked() {
      this.showUrlCta ? this.goToUrl() : this.toggleWindow()

      const data = {
        action: 'wp_sam_chat_cta_button_clicked',
      }

      axios.post(
        this.settings.apiUrl,
        this.getFormData(data)
      ).catch(console.error)
    },

    /**
     * @param object
     * @returns {FormData}
     */
    getFormData(object) {
      const formData = new FormData()
      Object.keys(object).forEach(key => formData.append(key, object[key]))
      return formData
    },

    openOnElements() {
      const openOnElements = document.querySelectorAll('#sam-chat-open, .sam-chat-open')
      openOnElements.forEach(element => {
        element.addEventListener(
          'click',
          () => {
            setTimeout(() => {
                this.toggleWindow()
            }, 50)
          })
      })
    },
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
